import React from "react";

import { Wrapper,Content } from "./footer.syle";

const Footer = ({bottom}) =>{

    return(
        <Wrapper>
            <Content className={bottom} >
                <div>
                <a href="https://axgura.com" target='_blank' >AXGURA &copy; 2023</a>
                </div>
                <div>
                <a href="https://twitter.com/axgura" target='_blank'>Twitter</a>
                <a href="/" target="_blank" >LinkedIn</a>
                {/* <a href="https://github.com/chirag-23" target="_blank" >GitHub</a> */}
                </div>
            </Content>
        </Wrapper>
    )
}

export default Footer