import React, { useEffect, useState } from "react";

import { Wrapper,Navbar,Content,Box1 } from "./account.style";

import Header from "../Header";

// Icons
import HomeIcon from '@mui/icons-material/Home';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PaymentIcon from '@mui/icons-material/Payment';
import BoltIcon from '@mui/icons-material/Bolt';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Account = () =>{

    const user = sessionStorage.getItem("axgura-user")?JSON.parse(sessionStorage.getItem("axgura-user")):null;
    //const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const SignOut = () =>{
        navigate("/");
        sessionStorage.setItem("axgura-user", null);
    }
    const getBlogData=async()=>{
        const url = `https://axgura-blog.herokuapp.com/v1/blog/all-blogs`;
        const {data} = await axios.get(url);
        console.log(data.data);
        if(data){
            setData(data.data);
        }else{
            setData([]);
        }
    }

    useEffect(()=>{
        if(user){
            getBlogData();
        }else{
            navigate("/login");
        }
    },[]);

    document.title='AXGURA Account | AXGURA';
    return(
        <>
        <Header/>
        <Wrapper>
            <Navbar>
                <div className="row" >
                    <div className="main home">
                        <HomeIcon className="logo" />
                    </div>
                    <div className="text high" >
                        <h2><a>Dashboard</a></h2>
                    </div>
                </div>
                {/* <div className="row" >
                    <div className="main">
                        <PermIdentityIcon className="logo"/>
                    </div>
                    <div className="text">
                        <h2><a>Profile Settings</a></h2>
                    </div>
                </div> */}
                {/* <div className="row" >
                    <div className="main">
                        <PaymentIcon className="logo"/>
                    </div>
                    <div className="text">
                        <h2><a>Payment Method</a></h2>
                    </div>
                </div>
                <div className="row" >
                    <div className="main">
                        <BoltIcon className="logo"/>
                    </div>
                    <div className="text">
                        <h2><a>Charging</a></h2>
                    </div>
                </div>
                <div className="row" >
                    <div className="main">
                        <AllInboxIcon className="logo"/>
                    </div>
                    <div className="text">
                        <h2><a>Loot Box</a></h2>
                    </div>
                </div>
                <div className="row" >
                    <div className="main">
                        <HistoryIcon className="logo"/>
                    </div>
                    <div className="text">
                        <h2><a>Order History</a></h2>
                    </div>
                </div> */}
                <div className="row" onClick={SignOut}>
                    <div className="main">
                        <LogoutIcon className="logo"/>
                    </div>
                    <div className="text">
                        <h2><a href="/" >Sign Out</a></h2>
                    </div>
                </div>
            </Navbar>
            <Content>
                <h1>{(user?.fullname).split(" ")[0] + "'s Dashboard"}</h1>
                <div className="container" >
                   {data && data.map(x=><div>
                    <Box1 bg={x.imageHeader}>
                    <div className="box box1" >
                        <div className="desc" >
                            <h3>{x.topic}</h3>
                            <p>{(x.description).length > 35?(x.description).substring(0,35)+"...":x.description}</p>
                            <a  href={`https://blog.axgura.com/post/${x.blog_id}`} >View Blog</a>
                        </div>
                    </div>
                    </Box1>
                   </div>)}
                   
                </div>
            </Content>
        </Wrapper>
        </>
    )
}

export default Account