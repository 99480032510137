import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { store } from './app/store';
// import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}>
     
    </Provider> */}
     <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
