import React,{ useState } from "react";
import { Content, Wrapper } from "./signup.style";
import Footer from "../Footer";
import { Link,useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
import LanguageIcon from '@mui/icons-material/Language';
// import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
// import { auth } from "../firebase";
// import { login } from "../../features/userSlice";
import { AxiosConnect } from "../../AxisoConnect";



const Signup = () =>{

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [phone_number, setPhone_number] = useState("");
    const [waitlistId, setWaitlistId] = useState("");
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    const CreateAccount=async(e)=>{
        e.preventDefault();
        {!fname && alert('Please Enter First Name!')};
        {!lname && alert('Please Enter Last Name!')};
        {!phone_number && alert('Please Enter Phone Number!')};
        const body = {
            "email":email,
            "firstname":fname,
            "lastname":lname,
            "phone_number":phone_number,
            "password":password,
            "waitlistId":waitlistId?waitlistId:null
        }
        console.table(body);
        try {
            const {data} = await AxiosConnect.post("/v1/user/create-account",body);
            if(data){
                console.log({data:data.data});
                sessionStorage.setItem("axgura-user",JSON.stringify(data.data));
                navigate("/account");
            }else{
                console.log({data:data.data});
                // error.message.replace(".", "");
                // alert(error.message + " (" + error.code + ")");
                document.getElementById("password").value = "";
            }    
        } catch (error) {
            const msg = error.response.data.message?error.response.data.message:error.message;
            alert(msg);
            console.log(msg)
        }
       
    };

    document.title='AXGURA - Register'
    return(
        <>
        <Wrapper>
            <div className="header" >
                <div>
                    <Link to='/'>
                        <img src="/images/logo.png" alt="logo"/>
                    </Link>
                </div>
                <div className="lang" >
                    <LanguageIcon/> <span>en-US</span>
                </div>
            </div>

            <Content>
                <h1>Create Account</h1>
                <form>
                    <label htmlFor="text" >First Name</label>
                    <input type='text' id='fname' value={fname} onChange={(e)=> setFname(e.target.value)} />
                    <label htmlFor="text" >Last Name</label>
                    <input type='text' id='lname' value={lname} onChange={(e)=> setLname(e.target.value)} />
                    <label htmlFor="text" >Phone Number</label>
                    <input type='text' id='pnumber' value={phone_number} onChange={(e)=> setPhone_number(e.target.value)} />
                    <label htmlFor="email" >Email Address</label>
                    <input type='email' id="email" value={email} onChange={(e)=> setEmail(e.target.value) }/>
                    <label htmlFor="password">Password</label>
                    <input type='password' id="password" value={password} onChange={(e)=> setPassword(e.target.value) }/>

                    <button type="submit" onClick={CreateAccount}>Create Account</button>
                </form>
                <div className="line" >
                    <hr/><span>OR</span><hr/>
                </div>

                <div>
                <Link to='/login'>
                    <button>Sign In</button>
                </Link>
                </div>
            </Content>
        </Wrapper>
        <br/>
        <Footer/>
        </>
    )
}

export default Signup