import styled from "styled-components";

export const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    gap: 50px;
    overflow-x: scroll;

    height: 120vh;
    
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 6px 30px;
        margin-right: 20px;

        img{
            margin-top: 10px;
            height: 57px;
            width: 150px;
        }
    }
    .lang{
        display: flex;
        align-items: center;
        cursor: pointer;
        span{
            padding-left:4px;
            font-size: 0.94rem;
        }
        padding: 10px;
        border-radius: 10px;
        transition: all 200ms ease-in;
        :hover{
            background-color: hsla(0,0%,50.2%,0.125);
        }
    }

    @media screen and (max-width: 400px){
        .header{
            margin: 6px 10px;
        }
    }
`;

export const Content = styled.div`
        display: flex;
        flex-direction: column;
        gap: 40px;
        min-width:320px;
        margin: 0 auto;
    form{
        display: flex;
        flex-direction: column;
        gap: 10px;

        label{
            color: #612E35;
            font-weight: 400;
            font-size: 0.95rem;
            padding-left: 15px;
        }
        input{
            background-color: #f4f4f4;
            margin-bottom: 20px;
            outline: none;
            border-radius: 0px;
            border:solid 1px #612E35;
            padding: 12px 20px;
            border-radius: 0px;
            font-size: 0.95rem;
            transition: all 0.2s;
            :focus{
                border: 1px solid lightgrey;
            }
        }
        button{
            border: none;
            outline: none;
            padding: 12px 20px;
            font-weight: 300;
            font-size: 0.85rem;
            letter-spacing: 0.6px;
            border-radius: 0px;
            border:solid 1px #612E35;
            color: white;
            text-transform: uppercase;
            background-color: #612E35;
            cursor: pointer;
            transition: all 300ms ease-in;
            :hover{
                color:#612E35;
                font-weight:bold;
                border:solid 3px #612E35;
                background-color:white;
            }
        }
    }

    h1{
        text-align: left;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 2.1rem;
        color: #612E35;
        margin-left: -20px;
    }

    button{
            min-width: 320px;
            outline: none;
            padding: 10px 20px;
            font-weight: 300;
            font-size: 0.9rem;
            letter-spacing: 0.6px;
            color: #612E35;
            text-transform: uppercase;
            background-color:white;
            border-radius: 0px;
            border:solid 2px #612E35;
            cursor: pointer;
            transition: all 300ms ease-in;
            :hover{
                background-color:#612E35;
                color:white ;
            }
    }

    .line{
        display: flex;
        align-items: center;
        justify-content: space-between;

        hr{
            width: 43%;
            height: 0;
            opacity: 0.4;
        }
        span{
            color: #5c5e62;
        }
    }

    @media screen and (max-width: 400px){
        h1{
            margin-left: 0;
            text-align: center;
        }
    }
`;