import React,{ useEffect } from "react";
import AOS from 'aos'
import "aos/dist/aos.css";

import { Wrapper,Content } from "./spec.style";

const Specs = ({productOutline}) =>{

    useEffect(() => {
        AOS.init({duration: 1400});
        AOS.refresh();
    }, []);


    return(
        <Wrapper>
            <Content>
                {productOutline && productOutline
                    .map((x)=>{
                        console.log(x);
                        if(x){
                            //Turn left
                            return (
                                <div>
                                    <div className="row" >
                    <div className="col" data-aos='fade-right'> 
                        <img src={x.image} alt={x.name}/>
                    </div>
                    <div className="col text" data-aos='fade-left'>
                        <h3> {x.name} </h3>
                        <p>{x.text}</p>
                    </div>
                                 </div>
                                </div>
                            )
                        }else{
                            return (
                                <div>
                                                    <div className="row" >
                            <div className="col text1" data-aos='fade-right'>
                                <h3> Stay Connected </h3>
                                <p> Multi-device Bluetooth, wireless and USB-C charging for every passenger, with enough power to fast-charge your tablets and laptop. </p>
                            </div>
                            <div className="col" data-aos='fade-left'> 
                                <img src="/images/connected.jpg" alt="connected"/>
                            </div>
                                 </div>
                                </div>
                            )
                        }
                    })
                }
            </Content>
        </Wrapper>
    )
}

export default Specs;