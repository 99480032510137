import React from 'react'
import { Wrapper,ContentTop,ContentMid,Content } from './section.style'
import { useNavigate } from 'react-router'

function Section({ title,desc,backgroundImg,link,leftbtn,rightbtn,arrow,range,speed,hp,top,specs }) {
    console.log({ title,desc,backgroundImg,link,leftbtn,rightbtn,arrow,range,speed,hp,top,specs })
    const navigate = useNavigate();
    const GoTo=(url)=>{
        console.log((url));
        //Relocate to URL...
        window.location = url;
    }
    const nav=(url,link)=>{
        if(link == "external"){
            GoTo(url);
        }else{
            // nav to URL...
            navigate(`/${url}`);
        }
    }
    return (
        <Wrapper bg={backgroundImg[0]} >
            <ContentTop>
                <h1>{title}</h1>
                <p>{desc} <a href={link.link}>{link.btntext}</a></p>
            </ContentTop>

            <div>
                <ContentMid className={arrow ? '' : 'buttons'} >
                    {leftbtn &&
                    <div className='left'>
                        <button onClick={()=>nav(link.link,"external")}>{`${leftbtn} PRODUCT`}</button>
                    </div>
                    }
                    {rightbtn && 
                        <div className='right'>
                            <button onClick={()=>nav(title,"internal")}>{rightbtn}</button>
                        </div>
                    }
                </ContentMid>
                {specs && 
                <Content>
                    <div className="Info-bar">
                        {
                         specs && specs
                         .map((x)=><div>
                             <div key={x.spec} className="Specs" >
                            <h2>{x.spec}</h2>
                            <p>@ ({x.use})</p>
                        </div>
                         </div>)
                        }

                        {/* <div className="Specs" >
                            <h2>{range} mi</h2>
                            <p>Range (EPA est.)</p>
                        </div>
                        <div className="Specs" >
                            <h2>{speed}s</h2>
                            <p>0-60 mph*</p>
                        </div>
                        <div className="Specs" >
                            <h2>{top} mph</h2>
                            <p>Top Speed</p>
                        </div> */}

                        {hp && 
                        <div className="Specs hp" >
                            <h2>{hp} hp</h2>
                            <p>Peak Power</p>
                        </div>
                        }
                        <div className="order_btn">
                            <button onClick={()=>GoTo(link.link)}> VIEW PRODUCT </button>
                        </div>
                    </div>
                </Content>
                }
                {arrow && 
                    <div className='arrow'>
                        <img src='/images/down-arrow.svg' alt='arrow'/>
                    </div>
                }
            </div>
        </Wrapper>
    )
}

export default Section
