import React, { useState } from "react";
import LanguageIcon from '@mui/icons-material/Language';
import { signInWithEmailAndPassword } from "firebase/auth";

import { Wrapper,Content } from "./login.style";
import Footer from "../Footer";
import { Link,useNavigate } from "react-router-dom";
// import { auth } from "../firebase";
// import { useDispatch } from "react-redux";
// import { login } from "../../features/userSlice";
import { AxiosConnect } from "../../AxisoConnect";

const Login = () =>{

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const Login =async (e)=>{
        e.preventDefault() //Doesn't reload page
        const body = {
            email,password
        };
        try{
            const {data} = await AxiosConnect.post("/v1/user/login",body);
        console.log(data);
        if(data){
            console.log({data:data.data})
            sessionStorage.setItem("axgura-user",JSON.stringify(data.data));
            alert("User logged In.");
            navigate(`/account`);
        }else{
            console.log("Something went worng...")
        }
        } catch (error) {
            const msg = error.response.data.message?error.response.data.message:error.message;
            alert(msg);
            console.log(msg)
        }



    }
   
    document.title = 'AXGURA SSO - Sign In'
    return(
        <>
        <Wrapper>
            <div className="header" >
                <div>
                    <Link to='/'>
                        <img src="/images/logo.png" alt="logo"/>
                    </Link>
                </div>
                <div className="lang" >
                    <LanguageIcon/> <span>en-US</span>
                </div>
            </div>

            <Content>
                <h1>Sign In</h1>
                <form>
                    <label htmlFor="email" >Email Address</label>
                    <input type='email' id="email" value={email} onChange={(e)=> setEmail(e.target.value) }/>
                    <label htmlFor="password">Password</label>
                    <input type='password' id="password" value={password} onChange={(e)=> setPassword(e.target.value) }/>

                    <button type="submit" onClick={Login}>Sign In</button>
                </form>
                <div className="line" >
                    <hr/><span>OR</span><hr/>
                </div>

                <div>
                <Link to='/signup'>
                    <button>Create Account</button>
                </Link>
                </div>
            </Content>
        </Wrapper><br/>
        <Footer/>
        </>
    )
}

export default Login;