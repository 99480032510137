import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100vh;
    width: 100vw;

    .title{
        height: 15vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        h1{
            color: #612E35;
            font-weight: 900;
            letter-spacing: 0.8px;
            font-size: 1.8rem;
        }
    }

    
`;

export const Content = styled.div`
    background-image:  ${props => `url('${props.bg}')`}; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    height: 85vh;
`;