import React, { useState } from 'react'
import { Wrapper,Content } from './home.style'
import Section from './Section'
import Header from './Header';
import Footer from './Footer';
import { AxiosConnect } from '../AxisoConnect';
import { useEffect } from 'react';

function Home() {
    const [feeds, setFeeds] = useState();
    const getFeeds=async()=>{
        try{
            const {data}= await AxiosConnect.get("/v1/product/feeds/name");
            console.log({dataGPA:data.data});
            if(data){
                setFeeds(data.data);
            }else{
                console.log("Something wemt wrong");
            }
        }catch (error) {
            const msg = error.response.data.message?error.response.data.message:error.message;
            alert(msg);
            console.log(msg)
        }
    }
    useEffect(()=>{
        getFeeds();
    },[])
    document.title = "Axgura";
    return (
        <>
        <Header/>
        <Wrapper>
            {feeds && feeds
            .map(x=> <Content>
                <Section title={x.name} desc={x.description} link={{link:x.link,btntext:x.name}} leftbtn={x.leftbtn} rightbtn={x.rightbtn} backgroundImg={x.backgroundImg} arrow="true"/>
                </Content>)}
            {/* <Content>
                <Section title="AXGURA" desc="Order Online for " link="Touchless Delivery" leftbtn="CUSTOM ORDER" rightbtn="EXISTING INVENTORY" backgroundImg="model-3.jpg" arrow="true"/>
            </Content> */}
            {/* <Content>
                <Section title="AX-TRAFFIC" desc="Order Online for " link="Touchless Delivery" leftbtn="CUSTOM ORDER" rightbtn="EXISTING INVENTORY" backgroundImg="model-y.jpg"/>
            </Content> */}

            {/* <Content>
                <Section title="Model S" desc="Order Online for " link="Touchless Delivery" leftbtn="CUSTOM ORDER" rightbtn="EXISTING INVENTORY" backgroundImg="model-s.jpg"/>
            </Content>
            <Content>
                <Section title="Model X" desc="Order Online for " link="Touchless Delivery" leftbtn="CUSTOM ORDER" rightbtn="EXISTING INVENTORY" backgroundImg="model-x.jpg"/>
            </Content>
            <Content>
                <Section title="Solar Panels" desc="Lowest Cost Solar Panels in America" leftbtn="ORDER NOW" rightbtn="LEARN MORE" backgroundImg="solar-panel.jpg"/>
            </Content>
            <Content>
                <Section title="Solar Roof" desc="Produce Clean Energy From Your Roof" leftbtn="ORDER NOW" rightbtn="LEARN MORE" backgroundImg="solar-roof.jpg"/>
            </Content>
            <Content>
                <Section title="Accessories" leftbtn="SHOP NOW" backgroundImg="accessories.jpg"/>
                <Footer/>
            </Content> */}
        </Wrapper>
        </>
    )
}

export default Home