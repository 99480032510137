import React, { useEffect, useState } from "react";
import { Wrapper,Content } from "./models.style";
import Section from "../Section";
import Interior from "../Interior_Specs";
import Specs from "../Gen_Spec";
import Header from "../Header";
import Footer from "../Footer";
import { AxiosConnect } from "../../AxisoConnect";
import { useParams } from "react-router";

const Axgura = () =>{
    const [product, setProduct] = useState(null);
    const {product_name} = useParams();
    console.log({product_name});
    const getProduct= async ()=>{
        try{
            let content = await AxiosConnect.get(`/v1/product/${product_name}`);
            content = await (await content).data;
            console.log({content});
            setProduct(content.data);
        }catch (error) {
            const msg = error.response.data.message?error.response.data.message:error.message;
            alert(msg);
            console.log(msg)
        }
        document.title = product_name;

    };

    useEffect(()=>{
        getProduct();
    },[]);

    return(
        <Wrapper>
            <Header/>
            {
                product && product !== null?
                <div>
                     <Content>
                <Section 
                title={product_name}
                link={{btntext:product_name,link:`https://product.axgura.com/${product.name}`}}
                desc={product.description} 
                backgroundImg={product.images}
                specs={product.speculation} 
                arrow="true" 
                // range='396'
                // speed='1.99'
                // top='200'
                hp={false}
                />
            </Content>
            <Content>
                <Interior 
                 des={product_name}
                 bg={product.images[0]}
                />
            </Content>
            <Content>
                <Specs
                    productOutline={product.productOutline}
                />
            </Content>
                </div>:
                <div>

                </div>
            }
            <Footer bottom='bottom'/>
        </Wrapper>
    )
}

export default Axgura;