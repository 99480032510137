import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root{
        --teslaColor : #612E35;
    }
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family:"Archivo" , sans-serif;
        color: #612E35;
    }
    body{
        width: 100%;
        overflow-x: hidden;
        font-size: 1.6rem;
        font-weight: 400;
        font-family: "Archivo", sans-serif;
        color:#612E35;
    }
    input {
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "Archivo", sans-serif;
    color:#612E35;
    }

    a {
    text-decoration: none;
    }

    ul {
    list-style: none;
    }

    img {
    max-width: 100%;
    }

    h3,
    h4 {
    font-weight: 500;
    }

`;

export default GlobalStyle