import React, { useEffect, useState } from 'react'
import { Wrapper,Content} from './sidenav.style'

import { Link } from 'react-router-dom'
import { AxiosConnect } from '../../AxisoConnect';
import { AltRoute } from '@mui/icons-material';

function SideNav({ show }) {
    const [menu, setMenu] = useState([]); 
    const [open,setOpen] = useState(false);

    const getMenu=async()=>{
        try {
            const {data} = await AxiosConnect.get("/v1/product/menu/name");
            console.log({dataOne:data.data});
            if(data){
                console.log("sucess");
                setMenu(data.data);
            }else{
                console.log("Something went wrong!");
            }
        } catch (error) {
            const msg = error.response.data.message?error.response.data.message:error.message;
            alert(msg);
            console.log(msg)
        }
    };
    const flip=()=>{
        alert("Flip isn't supported yet.")
    }

    useEffect(()=>{
        getMenu();
    },[]);
    return (
        <Wrapper show={show}>
            <Content>
                {/* <Link to='/axgura'><li className='half'>AXGURA</li></Link>
                <Link to='/ax-traffic'><li className='half'>AX-TRAFFIC</li></Link>
                <Link to='/qtree'><li className='half'>QUANTUM TREE</li></Link>
                 */}
                {/* <li className='half'>Solar Roof</li>
                <li className='half' >Shop</li> */}
                 {
                    menu && 
                    menu.map(x=><div>
                        <Link to={`/${x.name}`}><li className='half'>{(x.name)}</li></Link>
                    </div>)
                }
                <Link to='/login'><li className='half'>Account</li></Link>
                <li className='half'><a onClick={flip}>Flip</a></li>
            </Content>
        </Wrapper>
    )
}

export default SideNav