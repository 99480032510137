import React,{ useEffect, useState } from 'react'
import { Wrapper,Content,Content1 } from './header.style'
import { Link } from 'react-router-dom';
import SideNav from '../SideNav'
import CloseIcon from '@mui/icons-material/Close';
import { AxiosConnect } from '../../AxisoConnect';

const Header = () => {
    const [menu, setMenu] = useState([]); 
    const [open,setOpen] = useState(false);

    const getMenu=async()=>{
        try {
            const {data} = await AxiosConnect.get("/v1/product/menu/name");
            console.log({dataOne:data.data});
            if(data){
                console.log("sucess");
                setMenu(data.data);
            }else{
                console.log("Something went wrong!");
            }
        } catch (error) {
            const msg = error.response.data.message?error.response.data.message:error.message;
            alert(msg);
            console.log(msg)
        }
    };

    useEffect(()=>{
        getMenu();
    },[]);

    return (
        <>
        <Wrapper>
            <div className='logo' >
            <Link to='/'>
                <img src='/images/logo.png' alt='logo'/>
            </Link>
            </div>
            <Content>
                {
                    menu && 
                    menu.map(x=><div>
                        <Link to={x.name}>{(x.name)}</Link>
                      </div>)
                }
                {/* <Link to='/'> </Link> */}
            </Content>
            <Content1>
                <a href='https://shop.axgura.com' className='none' >Shop</a>
                <Link to='/login' className='none'>Account</Link>
                <a href='#' onClick={()=> setOpen(true)} >Menu</a>
            </Content1>
            {open && 
            <div className='top'>
                <div className='close' onClick={()=> setOpen(false)}>
                    <CloseIcon/>
                </div>
            </div> 
            }       
            <SideNav show={open}/>
        </Wrapper>
        </>
    )
}

export default Header